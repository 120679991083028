body {
  height: 100%;
  margin: 0;
  background-color: '#b2b2b2';
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Login-component {
  /* The image used */
  background-image: url(../src/assets/bg.jpg) !important;
  height: 100vh;
  height: auto; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ant-layout-header {
  background-color: "#ffffff" !important;
}

.logo{
  height: 60px;
}

.login-form{
  margin-top:200px;
}

.logomain{
   height: 60px;
   padding: 7px;;
}

.log2{
  margin: auto;

}

.headLogo{
  height: 50px;
  margin: 10px;
}
table tr th:first-child {
  width: 100px;
}
/*.wrapperbg_front {
	background-image: url('../src/assets/ziiki.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	transform: scale(1.5);
	filter: grayscale(100%);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0.3;
}*/

.skin-blue {
	background-color: #b2b2b2;
}
